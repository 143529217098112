export const toggleVisibility = (ele) => {
  ele.classList.toggle('hide');
};

const show = (ele) => {
  ele.classList.remove('hide');
};

const hide = (ele) => {
  ele.classList.add('hide');
};

export const getSectionImg = (index) => {
  return document.getElementById('covers').children[index];
};

export const getSectionPlayer = (index) => {
  return document.getElementById('players').children[index];
};

export const togglePlayers = (indexToShow) => {
  const players = Array.from(document.getElementById('players').children);
  const activePlayer = players.findIndex(
    (player) => !player.classList.contains('hide')
  );

  if (activePlayer === indexToShow) {
    return;
  }

  players.forEach((player, i) => {
    if (indexToShow === i) {
      show(player);
    } else {
      hide(player);
    }
  });
};
