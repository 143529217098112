import { togglePlayers, getSectionImg } from './modules/ele-util';

const Release = {
  Frugality: 0,
  Mothership: 1,
  LittleJack: 2,
};

document.addEventListener('DOMContentLoaded', () => {
  getSectionImg(Release.Frugality).onclick = () => {
    togglePlayers(Release.Frugality);
  };

  getSectionImg(Release.Mothership).onclick = () => {
    togglePlayers(Release.Mothership);
  };

  getSectionImg(Release.LittleJack).onclick = () => {
    togglePlayers(Release.LittleJack);
  };
});

if (module.hot) {
  module.hot.accept();
}
